import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/SEO'
import Media from '../components/Media'
import AboutTextImage from '../components/about/AboutTextImage'
import AboutCatchPhrase from '../components/about/AboutCatchPhrase'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  headerHeight
} from '../styles'

const About = ({
  data: {
    contentfulAboutPage: {
      slug,
      seoTitle,
      seoDescription,
      media,
      textImage,
      desktopSecondImage,
      catchPhrase
    }
  }
}) => {
  return (
    <>
      <SEO slug={slug} title={seoTitle} description={seoDescription} isPage />
      <MediaWrapper>
        <Media media={media} controlsOn />
      </MediaWrapper>
      <AboutTextImage textImage={textImage} secondImage={desktopSecondImage} />
      <AboutCatchPhrase catchPhrase={catchPhrase} />
    </>
  )
}

const MediaWrapper = styled.div`
  position: relative;
  width: 100%;
  top: ${headerHeight.mobile};
  padding: ${mobileVW(16)};
  height: ${mobileVW(643)};

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVW(235)};
    height: ${desktopVW(796)};
    padding: ${desktopVW(60)} ${desktopVW(60)} 0;
    margin-bottom: ${desktopVW(235)};
  }
`

export const PageQuery = graphql`
  query About($node_locale: String!) {
    contentfulAboutPage(node_locale: { eq: $node_locale }) {
      seoTitle
      seoDescription
      ...visionPage
    }
  }
`

export default About
