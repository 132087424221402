import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import RichText from '../RichText'
import TernaryLink from '../TernaryLink'
import Icon from '../Icon'
import useRootMargin from '../../hooks/window/useRootMargin'
import useHeaderColor from '../../hooks/useHeaderColor'
import { mobileVW, desktopVW, color, desktopBreakpoint } from '../../styles'

const AboutTextImage = ({
  textImage: { title, body, image, cta, link },
  secondImage
}) => {
  const rootMargin = useRootMargin()
  const [refAboutTextImageEl, inViewAboutTextImageEl] = useInView({ rootMargin })

  const firstParagraph = body => {
    const parsedBody = JSON.parse(body.body)
    return {
      data: parsedBody.data,
      content: [parsedBody.content[0]],
      nodeType: parsedBody.nodeType
    }
  }

  const otherParagraphs = body => {
    const parsedBody = JSON.parse(body.body)
    return {
      data: parsedBody.data,
      content: parsedBody.content.filter((p, index) => index !== 0),
      nodeType: parsedBody.nodeType
    }
  }

  useHeaderColor(
    inViewAboutTextImageEl,
    {
      left: color.yellow,
      center: color.black,
      right: color.balck
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  return (
    <StyledTextImage>
      <MobileView className='hide-on-desktop'>
        <TextWrapper>
          <Title>{title}</Title>
          <RichText json={JSON.parse(body.body)} />
          {cta && (
            <TernaryLink slug={link ? link.link[0].slug : null}>
              <Cta>
                {cta}
              </Cta>
            </TernaryLink>
          )}
        </TextWrapper>
        <ImageWrapper>
          <Image fluid={image.fluid} alt={image.alt} />
          <IconWrapper>
            <Icon use name='logo-monogram' />
          </IconWrapper>
        </ImageWrapper>
      </MobileView>

      <DesktopView className='hide-on-mobile'>
        <ImageWrapper ref={refAboutTextImageEl}>
          <Image fluid={image.fluid} alt={image.title} />
        </ImageWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <RichText json={firstParagraph(body)} />
        </TextWrapper>
        <TextWrapper className='second-text-wrapper'>
          <RichText json={otherParagraphs(body)} />
          {cta && (
            <TernaryLink slug={link ? link.link[0].slug : null}>
              <Cta>
                {cta}
              </Cta>
            </TernaryLink>
          )}
        </TextWrapper>
        <ImageWrapper className='second-image-wrapper'>
          <Image
            fluid={secondImage.fluid}
            alt={secondImage.title}
          />
          <IconWrapper>
            <Icon use name='logo-monogram' />
          </IconWrapper>
        </ImageWrapper>
      </DesktopView>
    </StyledTextImage>
  )
}

const StyledTextImage = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .hide-on-mobile {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;

    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none;
    }
  }
`

const MobileView = styled.div``

const DesktopView = styled.div`
  flex-wrap: wrap;
`

const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${mobileVW(60)} ${mobileVW(60)} ${mobileVW(30)};
  background: ${color.yellow};

  p {
    text-transform: initial;
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(26)};
    padding-bottom: ${mobileVW(10)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(60)} ${desktopVW(185)} 0 ${desktopVW(175)};
    width: 50%;

    p {
      font-size: ${desktopVW(16)};
      line-height: ${desktopVW(26)};
      padding: 0 ${desktopVW(16)} ${desktopVW(16)} 0;
    }
  }
`

const Title = styled.h3`
  position: relative;
  font-size: ${mobileVW(30)};
  line-height: ${mobileVW(25)};
  padding: 0 ${mobileVW(22)} ${mobileVW(32)} 0;
  word-break: break-word;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(40)};
    line-height: ${desktopVW(34)};
    padding: 0 0 ${desktopVW(40)} 0;
    word-break: break-word;
  }
`

const Cta = styled.div`
  display: inline-block;
  font-size: ${mobileVW(26)};
  line-height: ${mobileVW(32)};
  text-transform: initial;
  text-decoration-line: underline;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(26)};
    line-height: ${desktopVW(32)};
    padding-top: ${desktopVW(48)};
  }
`

const ImageWrapper = styled.div`
  height: ${mobileVW(823)};
  padding: ${mobileVW(22)} ${mobileVW(16)};
  position: relative;
  width: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(832)};
    padding: 0 0 0 ${desktopVW(60)};
    margin-top: ${desktopVW(60)};
    width: 50%;

    &.second-image-wrapper {
      padding: ${desktopVW(60)} ${desktopVW(60)} 0 0;
      margin-top: 0;
    }
  }
`

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: -20%;
  transform: translateY(-50%);
  height: ${mobileVW(267)};
  width: ${mobileVW(267)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(297)};
    width: ${desktopVW(297)};
    top: 30%;
    left: -10%;

    svg {
      path:nth-child(1) {
        fill: ${color.black};
      }
      path:nth-child(2) {
        fill: ${color.yellow};
      }
    }
  }
`

export default AboutTextImage
