import React, { useEffect } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useDispatch } from 'react-redux'
import { useInView } from 'react-intersection-observer'
import { setHeaderColor } from '../../actions'
import useRootMargin from '../../hooks/window/useRootMargin'
import { mobileVW, desktopVW, desktopBreakpoint, color, zIndex } from '../../styles'

const AboutCatchPhrase = ({
  catchPhrase: { titleFirstPart, titleSecondPart, image }
}) => {
  const dispatch = useDispatch()
  const rootMargin = useRootMargin()
  const [refAboutCatchPhrase, inViewAboutCatchPhrase] = useInView({
    rootMargin
  })

  useEffect(() => {
    if (inViewAboutCatchPhrase) {
      dispatch(
        setHeaderColor({
          left: color.black,
          center: color.yellow,
          right: color.black
        })
      )
    } else {
      dispatch(
        setHeaderColor({
          left: color.black,
          center: color.black,
          right: color.black
        })
      )
    }
  }, [dispatch, inViewAboutCatchPhrase])

  return (
    <StyledCatchPhrase>
      <TitleFirstPart>{titleFirstPart}</TitleFirstPart>
      <ImageWrapper ref={refAboutCatchPhrase}>
        <Image fluid={image.fluid} alt={image.alt} />
      </ImageWrapper>
      <TitleSecondPart>{titleSecondPart}</TitleSecondPart>
    </StyledCatchPhrase>
  )
}

const StyledCatchPhrase = styled.div`
  position: relative;
  height: ${mobileVW(998)};
  padding: ${mobileVW(30)} 0 0;
  margin-bottom: -${mobileVW(26)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(1769)};
    padding-top: ${desktopVW(110)};
  }
`

const TitleFirstPart = styled.h2`
  position: relative;
  width: 100%;
  word-break: break-word;
  font-size: ${mobileVW(123)};
  line-height: ${mobileVW(104)};
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(303)};
    line-height: ${desktopVW(243)};
    z-index: ${zIndex.low + 1};
    padding: 0;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  bottom: ${mobileVW(85)};
  left: 50%;
  transform: translateX(-50%) rotate(-2deg);
  height: ${mobileVW(483)};
  width: ${mobileVW(329)};

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVW(565)};
    width: ${desktopVW(703)};
    height: ${desktopVW(980)};
  }
`

const TitleSecondPart = styled.p`
  position: absolute;
  bottom: ${mobileVW(73)};
  font-size: ${mobileVW(10)};
  line-height: ${mobileVW(8)};
  width: 100%;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    bottom: ${desktopVW(155)};
    font-size: ${desktopVW(10)};
    line-height: ${desktopVW(8)};
  }
`

export default AboutCatchPhrase
